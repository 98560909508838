import React, { useMemo } from 'react'
import { Link, graphql } from 'gatsby'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import config from '../utils/config'

export default function Keyboards({ data, ...props }) {
    const keebs = data.allKeyboardsYaml.edges
    // const keebs = Object.entries(keyboards)

    return (
        <Layout>
            <Helmet title={`Keyboards | ${config.siteTitle}`} />
            <SEO customDescription="Articles, tutorials, snippets, musings, and everything else." />
            <header>
                <div className="container">
                    <h1>Keyboards.</h1>
                    <p>I build custom keyboards. Here are some of them.</p>
                </div>
            </header>
            <section>
                <div className="container">
                    {keebs.map((entry, i) => {
                        const keeb = entry.node
                        return (
                            <div>
                                <h4>{keeb.title}</h4>
                                <div className="tiles">
                                    {keeb.images.map((entry, i) => {
                                        return (
                                            <Zoom>
                                                <GatsbyImage
                                                    className="tile"
                                                    image={entry.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    placeholder="blurred"
                                                />
                                            </Zoom>
                                        )
                                    })}
                                </div>
                            </div>
                            )
                        })}
                </div>
            </section>
        </Layout>
    )
}

export const keyboardQuery = graphql`
  query KeyboardQuery {
    allKeyboardsYaml {
      edges {
        node {
          title
          images {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
  }
`
